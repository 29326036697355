export const selectSelectedDirection = state => state.mentor.selectedDirection;
export const selectName = state => state.mentor.name;
export const selectSurname = state => state.mentor.surname;

export const selectDirections = state => state.mentor.directions;
export const selectFormats = state => state.mentor.formats;
export const selectEmployments = state => state.mentor.employments;


export const selectActiveFormat = state => state.mentor.activeFormat;
export const selectCompanyName = state => state.mentor.companyName;
export const selectCompanyReturn = state => state.mentor.companyReturn;
export const selectCompetences = state => state.mentor.competences;
export const selectPhoto = state => state.mentor.photo;
export const selectVideoUrl = state => state.mentor.videoUrl;

export const selectLogin = state => state.mentor.login;
export const selectPassword = state => state.mentor.password;
export const selectInviteCode = state => state.mentor.inviteCode;
export const selectActiveBusiness = state => state.mentor.activeBusiness;

export const selectCityID = state => state.mentor.cityID;
export const selectCities = state => state.mentor.cities;
export const selectEmailToken = state => state.mentor.emailToken;
export const selectTransformedDirections = state => state.mentor.transformedDirections;
