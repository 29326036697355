import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { adaptObject, configureOptions } from '../../utils';
import { Method, Path, UrlPath } from '../../const';
import { fetchDirections, fetchEmployments, fetchFormats, setCities } from '../../store/mentor-reducer';

import { Box, Columns } from 'react-bulma-components';

import MentorCode from '../mentor-code/mentor-code';
import MentorLogin from '../mentor-login/mentor-login';
import MentorPassword from '../mentor-password/mentor-password';
// import MentorDirection from '../mentor-direction/mentor-direction';
import MentorInitials from '../mentor-initials/mentor-initials';
import MentorBusiness from '../mentor-business/mentor-business';
import MentorCompany from '../mentor-company/mentor-company';
import MentorPhoto from '../mentor-photo/mentor-photo';
import MentorVideo from '../mentor-video/mentor-video';
import MentorFormat from '../mentor-format/mentor-format';
import MentorCompetences from '../mentor-competences/mentor-competences';
import Submit from '../submit/submit';
import { useFetchData } from '../../hooks/use-fetch-data';
import MentorDirection from '../mentor-direction/mentor-direction';
import MentorCity from '../mentor-city/mentor-city';
import MentorEmailToken from '../mentor-email-token/mentor-email-token';
import Cookies from 'js-cookie';

const { Column } = Columns;

const MentorForm = () => {
  const dispatch = useDispatch();

  const [failedCompetences, setFailedCompetences] = useState(false);
  const [checkPassword, setCheckPassword] = useState(``);
  const [checkBusinesses, setCheckBusinesses] = useState(false);

  const [employments] = useFetchData(UrlPath.FETCH_EMPLOYMENTS, `GET`);
  const [directions] = useFetchData(UrlPath.FETCH_DIRECTIONS, `GET`);
  const [educations] = useFetchData(UrlPath.FETCH_EDUCATION_TYPES, `GET`);
  const [cities] = useFetchData(UrlPath.FETCH_CITIES, `GET`);

  useEffect(() => {

    if (educations) {
      dispatch(fetchFormats(educations));
    }

    if (cities) {
      dispatch(setCities(cities));
    }

    if (directions) {
      let newDirections = [];
      directions.forEach((direction) => {
        newDirections.push(adaptObject(direction));
      });

      dispatch(fetchDirections(newDirections));
    }

    if (employments) {
      dispatch(fetchEmployments(employments));
    }

  }, [dispatch, employments, directions, educations, cities]);

  return (
    <Box>
      <Columns>
        <Column className="is-one-third"></Column>
        <Column className="is-one-third">
          <button
            type='button'
            className='button'
            style={{ marginRight: `auto`, marginLeft: `auto`, width: `100%`, marginBottom: `16px` }}
            onClick={() => {
              Cookies.remove(`requestID`)
              window.location.reload()
            }}
          >Пройти регистрацию заново</button>
          <form className="mentor-form">
            <MentorCode />

            <MentorLogin />

            <MentorPassword checkPassword={checkPassword} setCheckPassword={setCheckPassword} />

            <MentorDirection />

            <MentorInitials />

            <MentorCity />

            <MentorFormat />

            <MentorBusiness checkBusinesses={checkBusinesses} setCheckBusinesses={setCheckBusinesses} />

            <MentorCompany />

            <MentorCompetences />

            <MentorPhoto />

            <MentorVideo />

            <MentorEmailToken />

            <Submit failedCompetences={failedCompetences} setFailedCompetences={setFailedCompetences} />
          </form>
        </Column>
        <Column className="is-one-third"></Column>
      </Columns>
    </Box>
  );
};

export default MentorForm;
