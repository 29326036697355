import React from 'react';
import { Form } from 'react-bulma-components';
import { useDispatch, useSelector } from 'react-redux';
import { removeActiveFormat, setActiveFormat } from '../../store/mentor-reducer';
import { selectActiveFormat, selectFormats } from '../../store/selectors';

const { Field, Label, Control } = Form;

const MentorFormat = () => {
  const dispatch = useDispatch();
  const formats = useSelector(selectFormats);
  const activeFormats = useSelector(selectActiveFormat);

  const formatHandler = ({ target: { value, checked } }) => {
    checked
      ? dispatch(setActiveFormat(+value))
      : dispatch(removeActiveFormat(+value))
  };

  return (
    <Field>
      <Label>ФОРМАТ</Label>
      <Control>
        {
          formats
            ? formats.map(({ name, ID }, index) => {
              return (
                  <Label
                    className="checkbox"
                    key={index}
                    className="mentor-form__label checkbox"
                    style={{ width: `200px` }}
                  >
                    {name}
                    <input
                      type="checkbox"
                      name="format"
                      className="mentor-form__checkbox"
                      checked={activeFormats ? activeFormats.includes(ID): false}
                      value={ID ? ID : ``}
                      onChange={formatHandler}
                    />
                  </Label>
              )
            })
            : null
        }
      </Control>
    </Field>

  );
};

export default MentorFormat;
