import axios from 'axios';
import React, { useState } from 'react';
import { UrlPath } from '../../const';
import MentorForm from '../mentor-form/mentor-form';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

const Main = () => {
  const [email, setEmail] = useState(``);
  const [isLoading, setIsLoading] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [isError, setIsError] = useState(false)

  const submitMentorMailHandler = async (evt) => {
    evt.preventDefault();
    setIsError(false)
    setIsLoading(true);

    try {
      const { data } = await axios.request({
        method: `POST`,
        url: UrlPath.EMAIL_CONFIRMATION,
        data: {
          email
        }
      })

      Cookies.set(`requestID`, data.requestID)
      setIsVerified(true);
    } catch (err) {
      console.log(err)
      setIsError(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (Cookies.get(`requestID`)) {
      setIsVerified(true);
    }
  }, [])

  return (
    <div className="main">

      {isVerified
        ? <MentorForm />
        
        : <form
          className='main__form'
          onSubmit={submitMentorMailHandler}
        >
          <label>
            <span>Email</span>
            <input
              required
              type="email"
              className='input'
              value={email}
              onChange={(({ target }) => setEmail(target.value))} />
          </label>
          <button className='button' type='submit'>Верифицировать</button>
          {isError && <strong style={{color: `red`}}>попробуйте другой email</strong>}
        </form>}

    </div>
  );
};

export default Main;
