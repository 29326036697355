import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
import { Path, UrlPath } from '../../const';
import MentorForm from '../mentor-form/mentor-form';

import 'react-dropdown/style.css';
import Success from '../success/success';
import Main from '../main/main';

const App = () => {
  
  return (
    <section className="app">
      <Switch>

        <Route
          exact
          path={Path.DEFAULT}
          component={Main}
        />
        <Route
          exact
          path={UrlPath.SUCCESS}
          component={Success}
        />
      </Switch>
    </section>
  )
}

export default App;