import React from 'react';
import { Form } from 'react-bulma-components';
import { useDispatch, useSelector } from 'react-redux';
import { setInviteCode } from '../../store/mentor-reducer';
import { selectInviteCode } from '../../store/selectors';

const {Field, Label, Control, Input} = Form;

const MentorCode = () => {
  const dispatch = useDispatch();
  const inviteCode = useSelector(selectInviteCode);
  
  return (
    <Field>
      <Label>ИНВАЙТ КОД</Label>
      <Control>
        <Input
          type="text"
          required
          value={inviteCode}
          onChange={({ target: { value } }) => dispatch(setInviteCode(value))}
          placeholder="код"
        />
      </Control>
    </Field>
  );
};

export default MentorCode;
