import React from 'react';
import { Form } from 'react-bulma-components';
import { useDispatch, useSelector } from 'react-redux';
import { setLogin } from '../../store/mentor-reducer';
import { selectLogin } from '../../store/selectors';

const { Field, Label, Control, Input } = Form;

const MentorLogin = () => {
  const dispatch = useDispatch();

  const login = useSelector(selectLogin);
  
  return (
    <Field>
      <Label>ЛОГИН (3-20 символов)</Label>
      <Control>
        <Input
          type="text"
          required
          minLength="3"
          maxLength="20"
          value={login}
          onChange={({ target: { value } }) => dispatch(setLogin(value.replaceAll(" ", "")))}
          placeholder="придумайте логин"
        />
      </Control>
    </Field>
  );
};

export default MentorLogin;
