import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { Form, Button } from 'react-bulma-components';
import { useSelector } from 'react-redux';
import browserHistory from '../../browser-history';
import { Method, Path, UrlPath } from '../../const';
import { setEmailToken } from '../../store/mentor-reducer';
import {
  selectActiveBusiness, selectActiveFormat, selectCityID, selectCompanyName, selectCompanyReturn, selectCompetences, selectEmailToken, selectEmployments, selectInviteCode, selectLogin, selectName, selectPassword, selectPhoto,
  // selectSelectedDirection,
  selectSurname, selectTransformedDirections, selectVideoUrl
} from '../../store/selectors';
import { BASE_URL, configureOptions } from '../../utils';

const { Field, Label } = Form;

const Submit = ({ failedCompetences, setFailedCompetences }) => {

  const login = useSelector(selectLogin);
  const password = useSelector(selectPassword);
  const inviteCode = useSelector(selectInviteCode);
  const activeBusiness = useSelector(selectActiveBusiness);
  const name = useSelector(selectName);
  const surname = useSelector(selectSurname);
  const companyName = useSelector(selectCompanyName);
  const companyReturn = useSelector(selectCompanyReturn);
  const competences = useSelector(selectCompetences);
  const photo = useSelector(selectPhoto);
  const activeFormat = useSelector(selectActiveFormat);
  const videoUrl = useSelector(selectVideoUrl);
  const cityID = useSelector(selectCityID);
  const directions = useSelector(selectTransformedDirections);
  const emailToken = useSelector(selectEmailToken);

  const [isLogin, setIsLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null)

  const submitFormHandler = async (evt) => {
    setIsLogin(false);
    setErrorMessage(null)
    evt.preventDefault();

    const limits = directions.map(({ limit }) => limit);

    if (limits[0] < 15 || limits[1] < 15) {
      return setErrorMessage(`Лимит не может быть меньше 20`)
    }

    const activeDirections = directions
      .filter((direction) => direction.checked === true)

    const directionsList = activeDirections.map((direction) => direction.value)
    const limitInfo = directions.map((direction) => {
      if(direction?.limit) {
        return {
          stateID: direction.value,
          limit: direction.limit,
        }
      }
    }).filter(el => el && el)

    let competenceList = [];

    competences.forEach(({ value }) => {
      if (value !== ``) {
        competenceList.push(value);
      }
    });

    if (!photo) {
      return
    }

    if (competenceList.length < 3 || !activeBusiness.length) {
      return setFailedCompetences(true);
    } else {
      setFailedCompetences(false);
    }


    const data = {
      username: login,
      password,
      firstname: name,
      lastname: surname,
      inviteToken: inviteCode,
      eductionTypeList: activeFormat,
      directionsList,
      cityID,
      employmentList: activeBusiness,
      competenceList,
      limitInfo,
      photoURL: photo,
      videoURL: videoUrl,
      companyName: companyName === `` ? null : companyName,
      requestID: Cookies.get(`requestID`),
      emailToken,
      companyAnnualTurnover: companyReturn === `` ? null : companyReturn,
    }


    // await fetch(BASE_URL + Path.SUBMIT, )
    axios
      .request(configureOptions(
        Method.POST,
        Path.SUBMIT,
        data
      ))
      .then(() => browserHistory.push(UrlPath.SUCCESS))
      .catch(({ response }) => {
        const { errorDetails } = response.data;

        switch (errorDetails) {
          case `mentor exists by name`:
            setErrorMessage(`На указанные ИФ уже создан ЛК, обратитесь к модератору`)
            break;
          case `Key: 'MentorCreateParams.Password' Error:Field validation for 'Password' failed on the 'min' tag`:
            setErrorMessage(`Пароль слишком короткий`)
            break
          case `token not found`:
            setErrorMessage(`Неверный инвайт код`)
            break
          case `wrong email token`:
            setErrorMessage(`Неверный код с почты`)
            break
          default:
            setErrorMessage(errorDetails)
            break
        }

        if (errorDetails.includes(`is greater than maximum value for Int4`)) {
          setErrorMessage(`Слишком большой доход`)
        }

      })
  };

  return (
    <>
      <Button
        onClick={submitFormHandler}
        className="is-success"
      >
        Отправить на модерацию
      </Button>
      {
        failedCompetences
          ? <Field>
            <Label style={{ color: `hsl(348, 100%, 61%)` }}>
              компетенций должно быть не менее трёх и должна быть выбрана хотя бы одна ниша
            </Label>
          </Field>
          : null
      }
      {
        errorMessage
          ? <Field>
            <Label style={{ color: `hsl(348, 100%, 61%)` }}>
              {errorMessage}
            </Label>
          </Field>
          : null
      }
    </>
  );
};

export default Submit;
