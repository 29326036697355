import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bulma-components';
import { useDispatch, useSelector } from 'react-redux';
import { businesses } from '../../const';
import { removeActiveBusiness, setActiveBusiness } from '../../store/mentor-reducer';
import { selectActiveBusiness, selectEmployments } from '../../store/selectors';

const { Field, Label, Control } = Form;

const MentorBusiness = ({ checkBusinesses, setCheckBusinesses }) => {
  const dispatch = useDispatch();
  const businessRef = useRef();

  const activeBusiness = useSelector(selectActiveBusiness);
  const employments = useSelector(selectEmployments);

  const [isDisabled, setIsDisabled] = useState(false);

  const businessHandler = ({ target: { value, checked } }) => {

    if (checked) {
      dispatch(setActiveBusiness(+value));
    } else {
      setCheckBusinesses(false);
      setIsDisabled(false);
      dispatch(removeActiveBusiness(+value));
    }
  };

  const setDisabledBoxes = (id) => {

    if (isDisabled && activeBusiness && !activeBusiness.includes(id)) {
      return true;
    }
    return null;
  }

  useEffect(() => {
    if (activeBusiness && activeBusiness.length === 3) {
      setCheckBusinesses(true);
      setIsDisabled(true);
    }
  }, [activeBusiness]);

  return (
    <Field>
      <Label>НИШИ</Label>
      {
        checkBusinesses
          ? <Label style={{ color: `red` }}>Максимум три ниши</Label>
          : null
      }

      <Control>
        {
          employments
            ? employments.map(({ name, ID }, index) => {
              return (
                <Label
                  key={index}
                  className="mentor-business__label"
                >
                  {name}
                  <input
                    ref={businessRef}
                    type="checkbox"
                    className="mentor-form__checkbox"
                    value={ID}
                    disabled={setDisabledBoxes(ID)}
                    onChange={businessHandler}
                  />
                </Label>
              )
            })
            : <div>loading...</div>
        }
      </Control>
    </Field>

  );
};

export default MentorBusiness;
