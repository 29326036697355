import React from 'react';
import { Form } from 'react-bulma-components';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-dropdown';
import { selectDirections, selectTransformedDirections } from '../../store/selectors';
import { setDirection, setDirectionLimit, setDirections, setTransformedDirections } from '../../store/mentor-reducer';
import Select from 'react-select'
import { useState } from 'react';
import { useEffect } from 'react';

const { Field, Label, Control } = Form;

const MentorDirection = () => {
  const dispatch = useDispatch();

  const directions = useSelector(selectDirections);
  const transformedDirections = useSelector(selectTransformedDirections);

  useEffect(() => {
    if (directions) {

      let transDirections = [];
      for (const direction of directions) {
        if (direction.value === 1) {
          transDirections.push({ ...direction, checked: false, limit: ``, limitName: `На рассмотрении`})
        } else if (direction.value === 2) {
          transDirections.push({ ...direction, checked: false, limit: ``, limitName: `Подтвержден`})
        } else if (direction.value === 3) {
          transDirections.push({ ...direction})
        }
      }

      dispatch(setTransformedDirections(transDirections))
    }
  }, [directions])


  return (
    <div>
      <Label>НАПРАВЛЕНИЯ: </Label>
      <div className="mentor-item__education-list">
        <Control>
          {directions && transformedDirections &&
            transformedDirections.map(({ value, label, checked, limit }, idx) => {

              return (
                <div key={value}>
                  <Label>
                    {label}
                    <input
                      type="checkbox"
                      className='mentor-form__checkbox'
                      value={value}
                      checked={checked}
                      onChange={(({ target }) => {
                        dispatch(setDirections({
                          directionIdx: idx,
                          isChecked: target.checked
                        })
                        )
                      })}
                    />
                  </Label>


                </div>
              )
            })}

          {directions && transformedDirections &&
            transformedDirections.map(({ value, label, checked, limit, limitName }, idx) => {
              if(limitName) {
                return (
                  <Label key={value}>
                   Лимит {limitName} (миниум 15) обязательное поле
                    <input
                     value={limit}
                      required
                      type="number"
                      step="1"
                      min="15"
                      className='input'
                      onChange={(({ target }) => dispatch(setDirectionLimit({
                      directionIdx: idx,
                        limit: target.value ? +target.value : ``
                    })))} />
                </Label>
              )
              }
            })}
        </Control>
      </div>
    </div>
  );
};

export default MentorDirection;
