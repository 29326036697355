import React from 'react';
import { Form, Textarea } from 'react-bulma-components';
import { useDispatch, useSelector } from 'react-redux';
import { setName, setSurname } from '../../store/mentor-reducer';
import { selectName, selectSurname } from '../../store/selectors';


const { Field, Label, Control, Input } = Form;

const MentorInitials = () => {
  const dispatch = useDispatch();
  const name = useSelector(selectName);
  const surname = useSelector(selectSurname);

  return (
    <>
      <Field>
        <Label>ИМЯ</Label>
        <Control>
          <Input
            type="text"
            required
            value={name}
            onChange={({ target: { value } }) => dispatch(setName(value))}
            placeholder="ваше имя"
          />
        </Control>
      </Field>

      <Field>
        <Label>ФАМИЛИЯ</Label>
        <Control>
          <Input
            type="text"
            required
            value={surname}
            onChange={({ target: { value } }) => dispatch(setSurname(value))}
            placeholder="ваша фамилия"
          />
        </Control>
      </Field>
    </>
  );
};

export default MentorInitials;
