import React from 'react';
import { Form } from 'react-bulma-components';
import { useDispatch, useSelector } from 'react-redux';
import { setCompanyName, setCompanyReturn } from '../../store/mentor-reducer';
import { selectCompanyName, selectCompanyReturn } from '../../store/selectors';


const { Field, Label, Control, Input } = Form;

const MentorCompany = () => {
  const dispatch = useDispatch();
  const companyName = useSelector(selectCompanyName);
  const companyReturn = useSelector(selectCompanyReturn);

  return (
    <>
      <Field>
        <Label>НАИМЕНОВАНИЕ КОМПАНИИ (необязательное поле)</Label>
        <Control>
          <Input
            value={companyName}
            onChange={({ target: { value } }) => dispatch(setCompanyName(value))}
          />
        </Control>
      </Field>

      <Field>
        <Label>ГОДОВОЙ ОБОРОТ КОМПАНИИ (необязательное поле)</Label>
        <Control>
          <Input
            type="number"
            min="1"
            max="10000000"
            value={companyReturn}
            onChange={({ target: { value } }) => { 
              value === ``
                ? dispatch(setCompanyReturn(``))
                : dispatch(setCompanyReturn(+value))
            }}
          />
        </Control>
      </Field>
    </>
  );
};

export default MentorCompany;
