import { BASE_URL } from "./utils";

export const Method = {
  GET: `GET`,
  POST: `POST`,
  PUT: `PUT`,
  DELETE: `DELETE`
}

const MENTOR = `/mentor`;
const LISTS = `/lists`;


export const UrlPath = {
  SUCCESS: `/success`,
  EMAIL_CONFIRMATION: BASE_URL + MENTOR + `/request_email_confirmation`,
  FETCH_EMPLOYMENTS: BASE_URL + LISTS + `/employment`,
  FETCH_DIRECTIONS: BASE_URL + LISTS + `/direction`,
  FETCH_EDUCATION_TYPES: BASE_URL + LISTS + `/education`,
  FETCH_CITIES: BASE_URL + LISTS + `/city`,
}

export const StatusCode = {
  OK: 200,
  NO_DATA: 201,
};

export const Path = {
  DEFAULT: `/mentor_registration`,
  SUBMIT: `${MENTOR}/register`,
  LISTS: `/mentor_registration/fetch_lists`,
  UPLOAD: `/upload`,
}

export const businesses = [
  `Автобизнес`,
  `Аренда`,
  `Брендинг`,
  `Бухгалтерский консалтинг`,
  `В2В продажи`,
  `Гостиничный бизнес`,
  `Дизайн`,
  `Инвестирование`,
  `Интернет-магазины`,
  `Криптовалюта`,
  `Маркетинг, СММ, блогерство`,
  `Маркетинговое агентство`,
  `Маркетплейсы`,
  `Медицина`,
  `Недвижимость`,
  `Озеленение и ландшафтный дизайн`,
  `Онлайн образование`,
  `Полиграфическая продукция`,
  `Продажа товаров и услуг`,
  `Продюсирование экспертов`,
  `Производство`,
  `Промышленные материалы`,
  `Развлечения`,
  `Реклама`,
  `Ремонт и реставрация изделий`,
  `Ремонт и строительство`,
  `Ресторанный бизнес`,
  `Розничная торговля`,
  `Сертификация`,
  `Технологии`,
  `Транспортные услуги`,
  `Услуги B2B`,
  `Услуги B2C`,
  `Финансовый консалтинг`,
  `Финансы и инвестиции`,
  `Финтех компания`,
  `Фотопродакшн`,
  `Экология`,
  `Digital`,
  `Event`,
]