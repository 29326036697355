import React from 'react';
import { Form } from 'react-bulma-components';
import { useDispatch, useSelector } from 'react-redux';
import { setCityID, setCompetency } from '../../store/mentor-reducer';
import { selectCities, selectCityID, selectCompetences } from '../../store/selectors';


const { Field, Label, Control, Textarea, Select } = Form;

const MentorCity = () => {
  const dispatch = useDispatch();
  const cities = useSelector(selectCities);
  

  const cityPickerHandler = ({ target }) => {
    const selectedIdx = target.options.selectedIndex
    const value = target.options[selectedIdx].value
    console.log(`value `,value)
    dispatch(setCityID(+value))
  }

  return (
    <Field>
      <Label>ГОРОД</Label>
      <Control>

        <Label>
          <Select
            onChange={cityPickerHandler}
          >
            <option value={''}>Выбрать</option>
            {cities && cities.map(({ name, ID }) => <option key={ID} value={ID}>{name}</option>)}
          </Select>

        </Label>

      </Control>
    </Field>

  );
};

export default MentorCity;
