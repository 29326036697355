import React from 'react';
import { Form } from 'react-bulma-components';
import { useDispatch, useSelector } from 'react-redux';
import { setPassword } from '../../store/mentor-reducer';
import { selectPassword } from '../../store/selectors';

const { Field, Label, Control, Input } = Form;

const MentorPassword = ({ checkPassword, setCheckPassword }) => {
  const dispatch = useDispatch();
  const password = useSelector(selectPassword);

  return (
    <>
      <Field>
        <Label>ПАРОЛЬ (8 - 29 символов)</Label>
        <Control>
          <Input
            type="password"
            minLength="8"
            maxLength="29"
            required
            value={password}
            onChange={({ target: { value } }) => dispatch(setPassword(value.replaceAll(" ", "")))}
            placeholder="придумайте пароль"
          />
        </Control>
      </Field>

      <Field>
        <Label>ПОВТОРИТЕ ПАРОЛЬ</Label>
        <Control>
          <Input
            type="password"
            minLength="8"
            required
            value={checkPassword}
            style={{
              borderColor:
                `${checkPassword.length
                  ? password === checkPassword
                    ? `green`
                    : `red`
                  : `#dbdbdb`
                }`

            }}
            onChange={({ target: { value } }) => setCheckPassword(value.replaceAll(" ", ""))}
            placeholder="повторите пароль"
          />
        </Control>
      </Field>
    </>
  );
};

export default MentorPassword;
