import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bulma-components';
import { selectEmailToken } from '../../store/selectors';
import { setEmailToken } from '../../store/mentor-reducer';

const { Field, Label, Control, Input } = Form;

const MentorEmailToken = () => {
  const dispatch = useDispatch();
  const emailToken = useSelector(selectEmailToken)

  return (
    <Field>
      <Control>
        <Label>КОД ПОДТВЕРЖДЕНИЯ С ПОЧТЫ</Label>
        <Input
          type="text"
          value={emailToken}
          onChange={(({ target }) => dispatch(setEmailToken(target.value)))}
        />

      </Control>
    </Field>
  );
};

export default MentorEmailToken;
