import React from 'react';
import { Form } from 'react-bulma-components';
import { useDispatch, useSelector } from 'react-redux';
import { setCompetency } from '../../store/mentor-reducer';
import { selectCompetences } from '../../store/selectors';


const { Field, Label, Control, Textarea } = Form;

const MentorCompetences = () => {
  const dispatch = useDispatch();
  const competences = useSelector(selectCompetences);
  
  return (
    <Field>
      <Label>КЛЮЧЕВЫЕ КОМПЕТЕНЦИИ (минимум 3)</Label>
      <Control>
        {
          competences.map(({ name, value, id }, index) => {

            return (
              <Label key={index}>
                {name}
                <Textarea
                  type="text"
                  maxLength="200"
                  id={id}
                  value={value}
                  placeholder="Максимум 200 символов"
                  onChange={({ target: { value } }) => dispatch(setCompetency({ value, id }))}
                />
              </Label>
            )
          })
        }
      </Control>
    </Field>

  );
};

export default MentorCompetences;
