import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {Router as BrowserRouter} from "react-router-dom";

import browserHistory from "./browser-history";

import store from './store/root-reducer';
import App from './components/app/app';

import 'bulma/css/bulma.min.css';
import './styles/styles.scss';


ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter history={browserHistory}>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
