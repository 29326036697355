import axios from 'axios';
import React, { useState } from 'react';
import { Button, Form } from 'react-bulma-components';
import { useDispatch, useSelector } from 'react-redux';
import { Method } from '../../const';
import { setPhoto, deletePhoto } from '../../store/mentor-reducer';
import { selectPhoto } from '../../store/selectors';
import { getBase64 } from '../../utils';

const { Field, Label } = Form;

const MentorPhoto = () => {
  const dispatch = useDispatch();
  const photo = useSelector(selectPhoto);

  const [state, setState] = useState({
    path: ``,
    url: ``,
  });

  const deletePhotoHandler = () => {
    dispatch(deletePhoto());

    axios.request({
      method: Method.DELETE,
      // url: `http://135.181.3.26:8099/delete`,
      url: `https://terramentorsmanager.ru/delete`,
      headers: {
        [`API-Key`]: `7f5cbd0f-40fe-47c8-ad48-b5d710abf915`,
        [`Content-Type`]: `application/json`
      },
      data: { path: state.path }
    })
  }

  const uploadPhotoHandler = ({ target }) => {
    const promise = new Promise((res, _rej) => {
      res(
        getBase64(target.files[0])
          .then((data) => data)
      )
    })

    promise
      .then((res) => {

        const imgRegex = /;base64,.*/
        const bytes = imgRegex.exec(res)[0].slice(8) 

        axios.request({
          method: Method.POST,
          url: `https://terramentorsmanager.ru/upload`,
          headers: {
            [`API-Key`]: `7f5cbd0f-40fe-47c8-ad48-b5d710abf915`,
            [`Content-Type`]: `application/json`
          },
          data: { bytes }
        })
          .then(({ data }) => {
            const { url, path } = data;

            dispatch(setPhoto(url));

            setState(state => ({
              ...state,
              path,
              url
            }))
          })
      })
  };

  return (
    <Field>
      <Label>ЗАГРУЗИТЕ ВАШЕ ФОТО (обязательное поле)</Label>
      <div className="file" style={{justifyContent: `space-between`}}>
        <label className="file-label">
          <input
            accept="image/png, image/jpeg"
            className="file-input"
            type="file"
            name="photo"
            required
            onInput={uploadPhotoHandler} />
          <span className="file-cta">
            <span className="file-label">
              {
                !photo
                  ? `Загрузить фото`
                  : `Фото загружено`
              }
            </span>
          </span>
        </label>
        {
          photo
            ? <Button
              type="button"
              className="is-warning"
              onClick={deletePhotoHandler}
            >
              Удалить фото
            </Button>
            : null
        }

      </div>
      {
        photo
          ? <img alt="img" style={{ marginTop: `20px` }} width="400" height="400" src={photo} />
          : null
      }
    </Field>

  );
};

export default MentorPhoto;
