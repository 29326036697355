import { createSlice } from '@reduxjs/toolkit';

export const mentorSlice = createSlice({
  name: 'mentor',
  initialState: {
    login: ``,
    password: ``,
    inviteCode: ``,
    activeBusiness: null,
    directions: [],
    formats: [],
    employments: [],
    selectedDirection: ``,
    name: ``,
    surname: ``,
    photo: ``,
    videoUrl: ``,
    activeFormat: null,
    companyName: ``,
    companyReturn: ``,
    cities: null,
    cityID: 1,
    emailToken: ``,
    activeDirections: [],
    transformedDirections: [],
    competences: [
      {
        name: `первая`,
        value: ``,
        id: 0,
      },
      {
        name: `вторая`,
        value: ``,
        id: 1,
      },
      {
        name: `третья`,
        value: ``,
        id: 2,
      },
      {
        name: `четвертая`,
        value: ``,
        id: 3,
      },
      {
        name: `пятая`,
        value: ``,
        id: 4,
      },
    ]
  },
  reducers: {
    fetchDirections: (state, action) => { state.directions = action.payload },
    fetchFormats: (state, action) => { state.formats = action.payload },
    fetchEmployments: (state, action) => { state.employments = action.payload },
    setTransformedDirections: (state, action) => {
      state.transformedDirections = action.payload
    },
    setDirections: (state, action) => {

      const { directionIdx, isChecked } = action.payload;

      const updatingDirItem = state.transformedDirections[directionIdx]
      updatingDirItem.checked = isChecked


      state.transformedDirections = [
        ...state.transformedDirections.slice(0, directionIdx),
        updatingDirItem,
        ...state.transformedDirections.slice(directionIdx + 1)
      ]
    },
    setDirectionLimit: (state, action) => {
      const { directionIdx, limit } = action.payload;
      const updatingDirItem = state.transformedDirections[directionIdx]
      updatingDirItem.limit = limit

      state.transformedDirections = [
        ...state.transformedDirections.slice(0, directionIdx),
        updatingDirItem,
        ...state.transformedDirections.slice(directionIdx + 1)
      ]
    },

    setName: (state, action) => { state.name = action.payload },
    setSurname: (state, action) => { state.surname = action.payload },
    setActiveFormat: (state, action) => {

      if (state.activeFormat && state.activeFormat.length) {
        state.activeFormat = [...state.activeFormat, action.payload]
      } else {
        state.activeFormat = [action.payload]
      }
    },
    removeActiveFormat: (state, action) => {
      let index;

      if (state.activeFormat) {
        index = state.activeFormat.findIndex((formatId) => formatId === action.payload);
      };

      if (state.activeFormat.length === 1) {
        state.activeFormat = null;
      } else {
        state.activeFormat =
          [
            ...state.activeFormat.slice(0, index),
            ...state.activeFormat.slice(index + 1)
          ]
      }
    },
    setCompanyName: (state, action) => { state.companyName = action.payload },
    setCompanyReturn: (state, action) => { state.companyReturn = action.payload },
    setCompetency: (state, action) => {
      const { id, value } = action.payload;
      state.competences[+id].value = value;
    },
    setPhoto: (state, action) => { state.photo = action.payload },
    deletePhoto: (state) => { state.photo = `` },
    setVideoUrl: (state, action) => { state.videoUrl = action.payload },
    setLogin: (state, action) => { state.login = action.payload },
    setPassword: (state, action) => { state.password = action.payload },
    setInviteCode: (state, action) => { state.inviteCode = action.payload },

    setActiveBusiness: (state, action) => {
      if (state.activeBusiness) {
        if (state.activeBusiness.length) {
          state.activeBusiness = [...[...new Set(state.activeBusiness)], action.payload];
        } else {
          state.activeBusiness = [state.activeBusiness, action.payload];
        }
      } else {
        state.activeBusiness = [action.payload];
      }
    },
    removeActiveBusiness: (state, action) => {
      let index;

      if (state.activeBusiness) {
        index = state.activeBusiness.findIndex((formatId) => formatId === action.payload);
      };

      if (state.activeBusiness.length === 1) {
        state.activeBusiness = null;
      } else {
        state.activeBusiness =
          [
            ...state.activeBusiness.slice(0, index),
            ...state.activeBusiness.slice(index + 1)
          ]
      }
    },
    setCityID: (state, action) => {
      state.cityID = action.payload
    },
    setCities: (state, action) => {
      state.cities = action.payload
    },
    setEmailToken: (state, action) => {
      state.emailToken = action.payload
    },
  },
});

export const {
  fetchDirections,
  fetchFormats,
  fetchEmployments,
  setDirections,
  setName,
  setSurname,
  setActiveFormat,
  removeActiveFormat,
  setCompanyName,
  setCompanyReturn,
  setCompetency,
  setPhoto,
  deletePhoto,
  setVideoUrl,
  setLogin,
  setPassword,
  setInviteCode,
  setActiveBusiness,
  removeActiveBusiness,
  setCityID,
  setCities,
  setEmailToken,
  setTransformedDirections,
  setDirectionLimit
} = mentorSlice.actions;

export default mentorSlice.reducer;
