import { useEffect, useState } from "react";
import axios from "axios";

export const useFetchData = (url, method = `POST`, data = null, trigger = null) => {

  const [fetchedData, setFetchedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);


  useEffect(() => {
    
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        
        const response = await axios.request({
          method,
          url,
          data
        });

        setFetchedData(response.data);
      } catch (error) {
        console.log(`error `,error)
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [data, trigger]);

  return [fetchedData, isLoading, isError, setFetchedData];
};
