import React from 'react';
import { Form } from 'react-bulma-components';
import { useDispatch, useSelector } from 'react-redux';
import { setVideoUrl } from '../../store/mentor-reducer';
import { selectVideoUrl } from '../../store/selectors';

const { Field, Label, Input } = Form;

const MentorVideo = () => {
  const dispatch = useDispatch();
  const videoUrl = useSelector(selectVideoUrl);
  
  return (
    <Field>
      <Label>ССЫЛКА НА ВИДЕО (обязательное поле)</Label>
      <Input
        placeholder="ссылка на видео на youtube"
        required
        value={videoUrl}
        onChange={({ target: { value } }) => dispatch(setVideoUrl(value))}
      />
      <Label>
        Если вы не давали интервью о себе операторам Терры - вам необходимо самостоятельно снять видеопрезентацию по инструкции, указанной ниже, загрузить видеоролик на Ютуб, и вставить ссылку на видео в поле ввода данных. Если у вас есть ссылка на интервью, проведенное организаторами Терры - введите ее.
      </Label>
      <div className="content">
        <ol type="1">
          <li>Выберите нейтральный фон и на камеру ответьте на вопросы</li>
          <li>Как вас зовут (Имя Фамилия)</li>
          <li>Кто вы? Предприниматель/управленец</li>
          <li>В какой нише ваш бизнес? (какие достижения, результаты)</li>
          <li>Какие у вас ключевые компетенции?</li>
          <li>Чем вы готовы делиться в наставничестве?</li>
          <li>Каких результатов хотите достичь с подопечными?</li>
          <li>Длительность видеоролика в пределах 1:30 - 2:00 минут</li>
        </ol>
      </div>
  
  
    </Field>

  );
};

export default MentorVideo;
