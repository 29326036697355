import Config from "./config.json";

// export const BASE_URL = `http://135.181.39.55:8780`;
export const BASE_URL = `https://${window.location.host}/api`;
// export const BASE_URL = Config.BASE_URL;
// export const BASE_URL = `https://2abb-185-196-238-166.ngrok.io`;

export const configureOptions = (method, path, data = null, responseType = ``) => {
  return {
    method,
    url: BASE_URL + path,
    data,
    responseType,
  }
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const adaptObject = (obj) => {
  const adaptedDirection = Object.assign(
    {},
    obj,
    {
      value: obj.ID,
      label: obj.name,
    });

  delete adaptedDirection.ID;
  delete adaptedDirection.name;

  return adaptedDirection;
}