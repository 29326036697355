import React from 'react'; 

const Success = () => {
  return (
   <div className="success">
     <h1 style={{textAlign: `center`}}>Вы были успешно зарегистрированы!</h1>
    </div>
 );
};

export default Success;
